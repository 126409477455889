import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";

import VueProgressBar from "@aacassandra/vue3-progressbar";
import progressbarConfig from "@/config/progressbar";

import PrimeVue from "primevue/config";

import ToastService from "primevue/toastservice";

import * as Sentry from "@sentry/vue";

import "./assets/main.scss";
import Toast from "primevue/toast";

const app = createApp(App);

app.use(VueProgressBar, progressbarConfig);
app.use(createPinia());
app.use(router);
app.use(PrimeVue);
app.use(ToastService);
app.component("Toast", Toast);

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENV || "local",
    integrations: [
        Sentry.browserTracingIntegration({router}),
        Sentry.replayIntegration(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.1,
    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [
        "localhost",
        /^https:\/\/(.*)turnmeroyal\.com/,
    ],

    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.mount("#app");
